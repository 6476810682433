:root {
  --grid-gutter: 1.25rem;
  --half-padding: 0.625rem;
  --base-padding: 1.25rem;
  --double-padding: 2.5rem;
  --triple-padding: 3.75rem;
  --quadriple-padding: 5rem;
  --eight-padding: 8rem;
  --base-border-radius: 0.375rem;
  --font-color: var(--color-type);
  --font-heading: "PT Serif",sans-serif;
  --font-base: "Raleway",sans-serif;
  --color-white: white;
  --color-orange: #DD8D74;
  --color-black: #030607;
  --color-darkgrey: #20221D;
  --color-type: #20221D;
  --color-facebook: #3b5998;
  --color-instagram: radial-gradient(
      circle at 30% 107%,
      #fdf497 0%,
      #fdf497 5%,
      #fd5949 45%,
      #d6249f 60%,
      #285aeb 90%
  );
  --color-twitter: #1da1f2;
  --color-linkedin: #0077b5;
  --color-whatsapp: #25d366;
}

body::before {
  content: "xs";
  display: none;
  visibility: hidden;
}
@media (min-width: 480px) {
  body::before {
    content: "sm";
  }
}
@media (min-width: 768px) {
  body::before {
    content: "md";
  }
}
@media (min-width: 60em) {
  body::before {
    content: "lg";
  }
}
@media (min-width: 90em) {
  body::before {
    content: "xl";
  }
}

.coverlink {
  position: absolute;
  z-index: 4;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  cursor: pointer;
}

.hiddentext {
  border: 0;
  font: 0/0 a;
  text-shadow: none;
  color: transparent;
}

.text-center {
  text-align: center;
}

.font-size-17 {
  font-size: 17px;
}

.font-size-23 {
  font-size: 23px;
}

.angle-top {
  content: " ";
  position: absolute;
  z-index: 1;
  top: -30px;
  left: 0;
  width: 0;
  height: 0;
  border-top: 30px solid transparent;
  border-right: 30px solid #fff;
}

.angle-top {
  color: #fff;
  border-right-width: 100%;
  border-top: 80px solid transparent;
  top: -80px;
}

.angle-bottom {
  content: " ";
  position: absolute;
  z-index: 1;
  bottom: -30px;
  left: 0;
  width: 0;
  height: 0;
  border-bottom: 30px solid transparent;
  border-left: 30px solid #fff;
}

.angle-bottom {
  color: #fff;
  border-left-width: 100%;
  border-bottom: 80px solid transparent;
  bottom: -80px;
}

.counter-top-skew {
  padding-top: 160px !important;
}

.key svg {
  margin: 1rem;
}
.key svg:first-child {
  margin-left: 0;
}

.link {
  display: inline-block;
  width: auto;
  color: var(--color-orange);
  text-decoration: none;
}
.link:hover {
  text-decoration: none;
}
.link:hover::after {
  width: 100%;
}
.link:hover svg {
  width: 2rem;
}
.link svg {
  fill: var(--color-orange);
  width: 1.25rem;
  height: 1.25rem;
  transition: width 0.3s ease;
}
.link::after {
  display: block;
  content: "";
  width: 0;
  height: 1px;
  background-color: var(--color-orange);
  transition: width 0.3s ease;
  margin-top: 0.5rem;
}

.button {
  position: relative;
  appearance: none;
  display: inline-block;
  /*display: flex;
  flex-direction: column;
  align-items: center;*/
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  white-space: normal;
  margin: 0;
  padding: 1rem 1.75rem;
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.5rem;
  background-image: linear-gradient(var(--color-orange), var(--color-orange)), linear-gradient(var(--color-black), var(--color-black));
  border: 2px solid var(--color-alloy);
  transition: all 0.2s ease-in;
}
.button:hover {
  background-position: left 100% bottom 5%, left bottom 5%;
  box-shadow: inset 0px 50rem 0 0 rgba(0, 0, 0, 0.2);
  text-decoration: none;
  outline: none;
  color: white;
}
.button:hover::before {
  transform: scaleX(1);
}
.button:hover span {
  color: var(--color-orange);
}
.button::before {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: left;
  transform-origin: left;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
  background-color: #20222a;
  z-index: 1;
}
.button.disabled, .button:disabled {
  background-color: var(--color-cadet);
  border-color: var(--color-cadet);
}
.button.disabled:hover, .button:disabled:hover {
  cursor: no-drop;
}
.button span {
  position: relative;
  transition: color 0.3s;
  z-index: 2;
}
.button--inverted {
  background-color: transparent;
  border: 2px solid var(--color-alloy);
  color: var(--font-color);
}
.button--inverted:hover {
  background-color: white;
  border: 2px solid white;
  color: var(--font-color);
}
.button--large {
  font-size: 1.25rem;
  padding: 1rem 1.5rem;
}
.button--note {
  font-size: 0.75rem;
  font-weight: normal;
  line-height: 1rem;
}
.button--white {
  background-color: var(--color-white);
  border-color: var(--color-white);
  color: var(--color-white);
}
.button--white:hover {
  border-color: var(--color-white);
}
.button--white.button--inverted {
  background-color: transparent;
  border-color: var(--color-white);
  color: var(--color-white);
}
.button--white.button--inverted:hover {
  background-color: var(--color-white);
  border-color: var(--color-white);
  color: var(--color-white);
}
.button--black {
  background-color: var(--color-black);
  border-color: var(--color-black);
  color: var(--color-white);
}
.button--black:hover {
  border-color: var(--color-black);
}
.button--black.button--inverted {
  background-color: transparent;
  border-color: var(--color-black);
  color: var(--color-black);
}
.button--black.button--inverted:hover {
  background-color: var(--color-black);
  border-color: var(--color-black);
  color: var(--color-white);
}
.button--darkgrey {
  background-color: var(--color-darkgrey);
  border-color: var(--color-darkgrey);
  color: var(--color-white);
}
.button--darkgrey:hover {
  border-color: var(--color-darkgrey);
}
.button--darkgrey.button--inverted {
  background-color: transparent;
  border-color: var(--color-darkgrey);
  color: var(--color-darkgrey);
}
.button--darkgrey.button--inverted:hover {
  background-color: var(--color-darkgrey);
  border-color: var(--color-darkgrey);
  color: var(--color-white);
}
.button--island {
  background-color: var(--color-island);
  border-color: var(--color-island);
  color: var(--color-white);
}
.button--island:hover {
  border-color: var(--color-island);
}
.button--island.button--inverted {
  background-color: transparent;
  border-color: var(--color-island);
  color: var(--color-island);
}
.button--island.button--inverted:hover {
  background-color: var(--color-island);
  border-color: var(--color-island);
  color: var(--color-white);
}
.button--cobalt {
  background-color: var(--color-cobalt);
  border-color: var(--color-cobalt);
  color: var(--color-white);
}
.button--cobalt:hover {
  border-color: var(--color-cobalt);
}
.button--cobalt.button--inverted {
  background-color: transparent;
  border-color: var(--color-cobalt);
  color: var(--color-cobalt);
}
.button--cobalt.button--inverted:hover {
  background-color: var(--color-cobalt);
  border-color: var(--color-cobalt);
  color: var(--color-white);
}
.button--zinc {
  background-color: var(--color-zinc);
  border-color: var(--color-zinc);
  color: var(--color-white);
}
.button--zinc:hover {
  border-color: var(--color-zinc);
}
.button--zinc.button--inverted {
  background-color: transparent;
  border-color: var(--color-zinc);
  color: var(--color-zinc);
}
.button--zinc.button--inverted:hover {
  background-color: var(--color-zinc);
  border-color: var(--color-zinc);
  color: var(--color-white);
}
.button--alloy {
  background-color: var(--color-alloy);
  border-color: var(--color-alloy);
  color: var(--color-white);
}
.button--alloy:hover {
  border-color: var(--color-alloy);
}
.button--alloy.button--inverted {
  background-color: transparent;
  border-color: var(--color-alloy);
  color: var(--color-alloy);
}
.button--alloy.button--inverted:hover {
  background-color: var(--color-alloy);
  border-color: var(--color-alloy);
  color: var(--color-white);
}
.button--pigeon {
  background-color: var(--color-pigeon);
  border-color: var(--color-pigeon);
  color: var(--color-white);
}
.button--pigeon:hover {
  border-color: var(--color-pigeon);
}
.button--pigeon.button--inverted {
  background-color: transparent;
  border-color: var(--color-pigeon);
  color: var(--color-pigeon);
}
.button--pigeon.button--inverted:hover {
  background-color: var(--color-pigeon);
  border-color: var(--color-pigeon);
  color: var(--color-white);
}
.button--cadet {
  background-color: var(--color-cadet);
  border-color: var(--color-cadet);
  color: var(--color-white);
}
.button--cadet:hover {
  border-color: var(--color-cadet);
}
.button--cadet.button--inverted {
  background-color: transparent;
  border-color: var(--color-cadet);
  color: var(--color-cadet);
}
.button--cadet.button--inverted:hover {
  background-color: var(--color-cadet);
  border-color: var(--color-cadet);
  color: var(--color-white);
}
.button--red {
  background-color: var(--color-red);
  border-color: var(--color-red);
  color: var(--color-white);
}
.button--red:hover {
  border-color: var(--color-red);
}
.button--red.button--inverted {
  background-color: transparent;
  border-color: var(--color-red);
  color: var(--color-red);
}
.button--red.button--inverted:hover {
  background-color: var(--color-red);
  border-color: var(--color-red);
  color: var(--color-white);
}
.button--maroon {
  background-color: var(--color-maroon);
  border-color: var(--color-maroon);
  color: var(--color-white);
}
.button--maroon:hover {
  border-color: var(--color-maroon);
}
.button--maroon.button--inverted {
  background-color: transparent;
  border-color: var(--color-maroon);
  color: var(--color-maroon);
}
.button--maroon.button--inverted:hover {
  background-color: var(--color-maroon);
  border-color: var(--color-maroon);
  color: var(--color-white);
}
.button--orange {
  background-color: var(--color-orange);
  border-color: var(--color-orange);
  color: var(--color-white);
}
.button--orange:hover {
  border-color: var(--color-orange);
}
.button--orange.button--inverted {
  background-color: transparent;
  border-color: var(--color-orange);
  color: var(--color-orange);
}
.button--orange.button--inverted:hover {
  background-color: var(--color-orange);
  border-color: var(--color-orange);
  color: var(--color-white);
}
.button--green {
  background-color: var(--color-green);
  border-color: var(--color-green);
  color: var(--color-white);
}
.button--green:hover {
  border-color: var(--color-green);
}
.button--green.button--inverted {
  background-color: transparent;
  border-color: var(--color-green);
  color: var(--color-green);
}
.button--green.button--inverted:hover {
  background-color: var(--color-green);
  border-color: var(--color-green);
  color: var(--color-white);
}
.button--type {
  background-color: var(--color-type);
  border-color: var(--color-type);
  color: var(--color-white);
}
.button--type:hover {
  border-color: var(--color-type);
}
.button--type.button--inverted {
  background-color: transparent;
  border-color: var(--color-type);
  color: var(--color-type);
}
.button--type.button--inverted:hover {
  background-color: var(--color-type);
  border-color: var(--color-type);
  color: var(--color-white);
}

.link-button {
  color: var(--color-island);
  text-decoration: underline;
}

body.menu-open #root {
  overflow: hidden;
}

canvas {
  position: fixed;
}

section {
  position: relative;
  /*@include breakpoint('md') {
      min-height: 100vh;
  }*/
}

#root {
  position: relative;
  z-index: 1;
}

.container {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 100rem;
  padding: 0 var(--grid-gutter);
}
@media (min-width: 768px) {
  .container {
    padding: 0 var(--triple-padding);
  }
}
.container--flex {
  display: flex;
}
.container--full {
  max-width: 100%;
  padding: 0;
}

.section--padding {
  padding: var(--quadriple-padding) 0;
}
@media (min-width: 768px) {
  .section--padding {
    padding: var(--eight-padding) 0;
  }
}

.section-header {
  display: flex;
  flex-direction: column;
  margin-bottom: var(--base-padding);
  width: 100%;
}

.page-header {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 22rem;
  margin-bottom: 1rem;
  padding: var(--base-padding);
}

.section-info {
  margin-top: auto;
  margin-right: auto;
}
@media (min-width: 768px) {
  .section-info {
    padding-left: 2rem;
  }
}

.section-header-row {
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media (min-width: 768px) {
  .section-header-row {
    flex-direction: row;
  }
}
.section-header-row .section-title {
  max-width: none;
  margin-right: auto;
}
@media (min-width: 768px) {
  .section-header-row .section-title {
    max-width: 60rem;
  }
}
.section-header-row .section-summary {
  max-width: none;
}
@media (min-width: 768px) {
  .section-header-row .section-summary {
    max-width: 60rem;
  }
}

.section-title {
  font-size: 1.875rem;
}
@media (min-width: 768px) {
  .section-title {
    font-size: 3.5rem;
  }
}

.section-summary {
  margin-bottom: var(--base-padding);
}

.section-subtitle {
  margin-bottom: var(--base-padding);
  font-size: 1rem;
  text-transform: uppercase;
  letter-spacing: 4px;
}

.intro .container--flex {
  flex-direction: column;
}
@media (min-width: 768px) {
  .intro .container--flex {
    flex-direction: row;
  }
}

.content {
  display: flex;
}

.content-box {
  padding-left: var(--base-padding);
  padding-right: var(--base-padding);
}
@media (min-width: 768px) {
  .content-box {
    padding-left: var(--double-padding);
    padding-right: var(--double-padding);
  }
}

.image-contain {
  object-fit: contain;
}

.intro__content {
  position: relative;
  z-index: 1;
  align-self: center;
  background-color: white;
  padding: var(--base-padding);
  font-size: 0.875rem;
}
@media (min-width: 768px) {
  .intro__content {
    padding: var(--double-padding) var(--triple-padding) var(--double-padding) var(--double-padding);
    margin-right: -2.5rem;
    font-size: 1.125rem;
    padding: var(--double-padding);
    padding-right: var(--quadriple-padding);
  }
}
.intro__content--dark {
  width: 100%;
  background-color: #eee;
}

.intro__image {
  display: flex;
  position: relative;
  z-index: 1;
  flex: 1 0 50%;
  margin: 0 -1.25rem;
}
@media (min-width: 768px) {
  .intro__image {
    margin: 0;
  }
}

.intro__video {
  display: flex;
  position: relative;
  z-index: 1;
  flex: 1 0 50%;
  margin: 0 -1.25rem;
}
.intro__video video {
  object-fit: contain;
}
@media (min-width: 768px) {
  .intro__video {
    margin: 0;
  }
}

.intro__software {
  display: flex;
  flex-direction: column;
  padding: var(--double-padding);
  position: relative;
  z-index: 1;
  flex: 1 0 50%;
  margin: 0 -1.25rem;
  background: white;
}
.intro__software li {
  padding: 3px 0;
  list-style: none;
}
.intro__software img {
  height: 16px;
  width: 16px;
  float: left;
  display: block;
  margin: 1px 5px;
}

.half {
  display: flex;
  flex-direction: column;
  flex: 1 0 50%;
}

.software {
  display: flex;
}

.streaming {
  position: relative;
}
.streaming .container--flex {
  flex-direction: column;
}
@media (min-width: 768px) {
  .streaming .container--flex {
    flex-direction: row;
  }
}

.eight__bit {
  position: absolute;
  z-index: 1;
  top: -67px;
  width: 4rem;
}

.numbers {
  color: white;
}

.reviews {
  overflow: hidden;
}

.grid {
  display: flex;
  display: grid;
  grid-gap: 1rem;
}

.no-cssgrid .grid {
  flex-direction: row;
  flex-wrap: wrap;
}

.grid--two {
  grid-template-columns: 1fr;
}
@media (min-width: 768px) {
  .grid--two {
    grid-template-columns: 1fr 1fr;
  }
}

.no-cssgrid .grid--two {
  flex-direction: row;
  flex-wrap: wrap;
}
.no-cssgrid .grid--two .grid-item {
  width: calc(50% - 1rem);
  margin: 0.5rem;
}

.grid--three {
  grid-template-columns: 1fr;
}
@media (min-width: 768px) {
  .grid--three {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.no-cssgrid .grid--three .grid-item {
  width: calc(33.33% - 1rem);
  margin: 0.5rem;
}

.grid--four {
  grid-template-columns: 1fr;
}

.no-cssgrid .grid--four .grid-item {
  width: calc(25% - 1rem);
  margin: 0.5rem;
}

.grid--five {
  grid-template-columns: 1fr;
}

.no-cssgrid .grid--five .grid-item {
  width: calc(20% - 1rem);
  margin: 0.5rem;
}

.grid--featured {
  grid-template-columns: 1fr;
  grid-gap: 0;
  background-color: #101110;
}
@media (min-width: 768px) {
  .grid--featured {
    grid-template-columns: 1fr 1fr;
  }
}
.grid--featured div:not(.grid-intro) {
  margin: var(--base-padding);
}
@media (min-width: 768px) {
  .grid--featured div:not(.grid-intro) {
    margin: 0;
  }
}
.grid--featured div:not(.grid-intro):nth-child(2) {
  margin-bottom: 0;
}

.grid--clients {
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 3rem;
}
@media (min-width: 768px) {
  .grid--clients {
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 5rem;
  }
}

.grid--awards {
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 3rem;
}
@media (min-width: 768px) {
  .grid--awards {
    grid-template-columns: repeat(6, 1fr);
  }
}

.grid--recommendations {
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 3rem;
}
@media (min-width: 768px) {
  .grid--recommendations {
    grid-template-columns: repeat(3, 1fr);
  }
}

.grid--websites {
  grid-template-columns: repeat(2, 1fr);
  margin: 1rem;
}
@media (min-width: 768px) {
  .grid--websites {
    grid-template-columns: repeat(4, 1fr);
  }
}

.grid--services {
  grid-template-columns: repeat(2, 1fr);
  width: 50%;
}

.grid-intro {
  grid-row: 1;
}
@media (min-width: 768px) {
  .grid-intro {
    grid-row: 1/3;
  }
}

.grid--certifications {
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
}
@media (min-width: 768px) {
  .grid--certifications {
    grid-template-columns: repeat(5, 1fr);
  }
}

.works {
  display: none;
}

.grid-gap-4 {
  grid-gap: var(--triple-padding);
}
@media (min-width: 768px) {
  .grid-gap-4 {
    grid-gap: var(--quadriple-padding);
  }
}

.grid-gap-8 {
  grid-gap: var(--triple-padding);
}
@media (min-width: 768px) {
  .grid-gap-8 {
    grid-gap: var(--eight-padding);
  }
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-weight: inherit;
  font-style: inherit;
  font-size: 100%;
  font-family: inherit;
  vertical-align: baseline;
}

html {
  width: 100%;
  box-sizing: border-box;
  font-family: var(--font-base);
  font-weight: 400;
  line-height: 1.25;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -ms-overflow-style: scrollbar;
}

* {
  margin: 0;
  padding: 0;
  font-family: var(--font-base);
}
*, *::before, *::after {
  box-sizing: inherit;
}

body {
  color: var(--font-color);
  font-family: var(--font-base);
  font-size: 1rem;
  line-height: 1.5;
  overflow-wrap: break-word;
  scroll-behavior: smooth;
  overflow-x: hidden;
  background-color: #eee;
}

hr {
  height: 0;
  overflow: visible;
}

nav ol,
nav ul {
  list-style: none;
}

.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

a {
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}
a:hover {
  text-decoration: underline;
  cursor: pointer;
}

b,
strong {
  font-weight: bold;
}

::selection,
::-moz-selection {
  background-color: var(--selection-color);
  color: var(--font-color);
  text-shadow: none;
}

img {
  max-width: 100%;
  height: auto;
  -ms-interpolation-mode: bicubic;
  display: inline-block;
  vertical-align: middle;
  border: 0;
  border-style: none;
}

button,
input,
select,
textarea {
  color: inherit;
  font: inherit;
}

button,
input,
select {
  overflow: visible;
}

button {
  background: transparent;
  border: 0;
  padding: 0;
  cursor: pointer;
  -webkit-font-smoothing: inherit;
  letter-spacing: inherit;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

input {
  overflow: visible;
}

textarea {
  resize: vertical;
  overflow: auto;
}

select {
  text-transform: none;
}

::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

:focus {
  outline: var(--color-cobalt) auto 1px;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

caption,
th,
td {
  text-align: left;
  font-weight: normal;
}

audio,
canvas,
iframe,
svg,
video {
  vertical-align: middle;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

svg:not([fill]) {
  fill: currentColor;
}

svg:not(:root) {
  overflow: hidden;
}

a,
area,
button,
input,
label,
select,
summary,
textarea,
[tabindex] {
  touch-action: manipulation;
}

[aria-busy=true] {
  cursor: progress;
}

[aria-controls] {
  cursor: pointer;
}

[aria-disabled=true],
[disabled] {
  cursor: not-allowed;
}

[aria-hidden=false][hidden]:not(:focus) {
  clip: rect(0, 0, 0, 0);
  display: inherit;
  position: absolute;
}

video {
  object-fit: cover;
  height: 100%;
  width: 100%;
}

details {
  margin-bottom: 1rem;
}

.background--white {
  background-color: var(--color-white);
}

.background--black {
  background-color: var(--color-black);
}

.background--darkgrey {
  background-color: var(--color-darkgrey);
}

.background--island {
  background-color: var(--color-island);
}

.background--cobalt {
  background-color: var(--color-cobalt);
}

.background--zinc {
  background-color: var(--color-zinc);
}

.background--alloy {
  background-color: var(--color-alloy);
}

.background--pigeon {
  background-color: var(--color-pigeon);
}

.background--cadet {
  background-color: var(--color-cadet);
}

.background--red {
  background-color: var(--color-red);
}

.background--maroon {
  background-color: var(--color-maroon);
}

.background--orange {
  background-color: var(--color-orange);
}

.background--green {
  background-color: var(--color-green);
}

.background--type {
  background-color: var(--color-type);
}

.background--skew-left {
  background: linear-gradient(135deg, #20221d 0%, #20221d 50%, #eee 50%, #eee 100%);
}

.background--skew-right {
  background: linear-gradient(135deg, #fff 0%, #fff 50%, #20221d 50%, #20221d 100%);
}

.background--image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.background--image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.background--black {
  color: white;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: var(--base-padding);
  line-height: 1.5;
  @import url("https://fonts.googleapis.com/css2?family=PT+Serif:ital,wght@0,400;0,700;1,400;1,700&display=swap");
  font-family: "PT Serif", serif;
}

h1 {
  font-size: 3.5rem;
}

h2 {
  font-size: 3rem;
  line-height: 1.2;
}
@media (min-width: 768px) {
  h2 {
    font-size: 3.5rem;
  }
}

h3 {
  font-size: 1.875rem;
}

h4,
h5,
h6 {
  font-size: 1.25rem;
}

a {
  display: block;
  color: var(--color-island);
}

p + p, p + a, p + h3 {
  margin-top: var(--base-padding);
}

ul.bullet-list {
  margin-left: var(--double-padding);
  margin-bottom: 0;
}
@media (min-width: 768px) {
  ul.bullet-list {
    margin-bottom: var(--base-padding);
  }
}
ul.bullet-list li {
  padding-bottom: var(--half-padding);
}

ul.stat-list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  list-style: none;
}
@media (min-width: 768px) {
  ul.stat-list {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}
ul.stat-list li {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: var(--base-padding);
  text-align: center;
  font-size: 1rem;
}
@media (min-width: 768px) {
  ul.stat-list li {
    margin-right: var(--base-padding);
    font-size: 20px;
  }
}
ul.stat-list span {
  color: var(--color-orange);
  margin-right: 0.5rem;
  font-size: 2rem;
  font-weight: 900;
}
@media (min-width: 768px) {
  ul.stat-list span {
    font-size: 7rem;
  }
}

.center-text {
  text-align: center;
}

.filters {
  display: none;
  list-style: none;
}
.filters a {
  text-decoration: none;
}

.filter {
  position: relative;
  min-width: auto;
  width: 100%;
}
@media (min-width: 768px) {
  .filter {
    position: absolute;
    right: var(--base-padding);
    bottom: var(--base-padding);
    min-width: 20rem;
    width: auto;
  }
}
.filter input {
  width: 100%;
  padding: 1rem;
}

.logo {
  /*background-color: #ED5E15;
  background-image: linear-gradient(to right,#CA35A3,#ED5E15);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1rem;
  padding: 1rem;
  width: 10rem;
  */
}
@media (min-width: 768px) {
  .logo {
    width: 12rem;
  }
}
.logo:hover path {
  fill: var(--color-orange);
}
.logo path {
  transition: fill 0.3s ease;
}

header .container {
  justify-content: space-between;
  align-items: center;
  max-width: none;
}

/* ==========================================================================
   Footer.
   ========================================================================== */
.footer {
  position: relative;
  width: 100%;
  background-color: var(--color-black);
  color: white;
  background-repeat: no-repeat;
  background-position: top center;
  text-align: center;
  font-size: 1rem;
}
@media (min-width: 768px) {
  .footer {
    text-align: left;
  }
}
.footer__bar {
  background-color: white;
  color: var(--color-mid-blue);
}
.footer .container--flex {
  position: relative;
  z-index: 1;
  flex-direction: column;
}
@media (min-width: 768px) {
  .footer .container--flex {
    flex-direction: row;
    flex-wrap: wrap;
  }
}
.footer .container-full {
  max-width: 1920px;
}
.footer h1, .footer h2, .footer h3, .footer h4, .footer h5, .footer h6,
.footer .h1, .footer .h2, .footer .h3, .footer .h4, .footer .h5, .footer .h6 {
  color: var(--color-mid-blue);
  margin: 0 0 var(--base-padding);
}
.footer a {
  color: white;
  font-size: 1rem;
  text-decoration: none;
}
.footer a:hover {
  text-decoration: underline;
  color: white;
}
.footer ul {
  padding: 0;
}
.footer li {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-bottom: var(--double-padding);
  line-height: 1.5;
}
.footer p {
  line-height: 2rem;
}
.footer img {
  margin-bottom: var(--base-padding);
}
.footer .logo {
  flex: 1 0 auto;
  padding: 0;
  width: 10.625rem;
  margin: 0 auto var(--triple-padding) auto;
}
@media (min-width: 768px) {
  .footer .logo {
    margin: 0 0 var(--triple-padding) 0;
  }
}
.footer .logo h1 {
  margin: 0;
}
.footer .logo::after {
  display: none;
}
.footer .footer__content {
  display: flex;
  flex-direction: column;
  margin-top: var(--triple-padding);
  margin-bottom: var(--base-padding);
}
@media (min-width: 768px) {
  .footer .footer__content {
    flex: 1 0 50%;
  }
}
@media (min-width: 60em) {
  .footer .footer__content {
    flex: 1 0 auto;
  }
}
.footer .footer__content h2 {
  font-size: 1.5rem;
}
.footer .footer__content--right {
  text-align: right;
}
.footer .footer__content--credit {
  text-align: center;
}
.footer .footer__content--credit span:not(.icon) {
  color: #E8E7E7;
  margin-bottom: var(--half-padding);
}
@media (min-width: 60em) {
  .footer .footer__content--credit {
    text-align: right;
  }
}

.bullets-social {
  display: flex;
  margin: 0 auto;
  margin-bottom: var(--base-padding);
  justify-content: center;
}
@media (min-width: 768px) {
  .bullets-social {
    margin: 0;
    margin-bottom: var(--base-padding);
  }
}
@media (min-width: 60em) {
  .bullets-social {
    justify-content: flex-start;
  }
}
.bullets-social li {
  margin-right: 1rem;
}
.bullets-social a:hover {
  text-decoration: none;
}
.bullets-social a:hover span::before {
  color: var(--color-red);
}
.bullets-social span::before {
  font-size: 2rem;
}

.backtotop {
  font-size: 0.875rem;
  display: flex;
  align-self: center;
  align-items: center;
  margin-bottom: var(--base-padding);
}
@media (min-width: 768px) {
  .backtotop {
    align-self: flex-end;
    margin-bottom: auto;
  }
}

.backtotop .icon {
  height: 2.5rem;
  width: 2.5rem;
  display: inline-flex;
  border: 2px solid var(--color-orange);
  justify-content: center;
  align-items: center;
  margin-left: 1rem;
  padding: 8px;
}

.footer__image {
  position: absolute;
  bottom: 0;
  right: 0;
  height: 20rem;
  width: 34rem;
}

.footer__socials {
  display: flex;
  justify-content: center;
  margin-top: var(--triple-padding);
}
@media (min-width: 768px) {
  .footer__socials {
    justify-content: flex-start;
  }
}
.footer__socials li {
  margin-right: var(--base-padding);
}

.footer__links {
  display: none;
}
@media (min-width: 768px) {
  .footer__links {
    display: block;
  }
}

.card {
  display: flex;
  align-items: center;
  justify-content: center;
  filter: grayscale(1);
}
.card--large {
  grid-column-end: span 2;
}
.card--recommendation {
  height: 100%;
  position: relative;
  flex-direction: column;
  justify-content: start;
  background-color: white;
  filter: none;
}
.card--recommendation .card__stars {
  display: flex;
  margin-bottom: var(--base-padding);
}
.card--recommendation .card__stars svg {
  fill: var(--color-orange);
  margin-right: 2px;
  width: 1.25rem;
  height: 1.25rem;
}
.card--recommendation .card__profile {
  display: flex;
  align-self: flex-start;
  margin-top: auto;
  padding: 0 var(--double-padding) var(--double-padding);
}
.card--recommendation .card__info {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.card--recommendation .card__name {
  font-weight: 600;
  margin-bottom: 0.5rem;
}
.card--recommendation .card__title, .card--recommendation .card__company {
  font-size: 0.875rem;
}
@media (min-width: 768px) {
  .card--recommendation .card__title, .card--recommendation .card__company {
    font-size: 1rem;
  }
}
.card--recommendation .card__image {
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  margin-right: var(--base-padding);
  overflow: hidden;
}
.card--recommendation .card__image img {
  width: 5rem;
  height: 5rem;
  object-fit: cover;
}
.card--recommendation .card__recommendation {
  font-style: italic;
  font-size: 1rem;
  line-height: 1.5rem;
  padding: var(--double-padding);
}
@media (min-width: 768px) {
  .card--recommendation .card__recommendation {
    font-size: 1.125rem;
  }
}
.card--website {
  filter: none;
  position: relative;
  height: 34rem;
  justify-content: start;
  align-items: flex-start;
  overflow: hidden;
}
.card--website:hover::before {
  opacity: 0.5;
  transition: opacity 0.3s ease;
}
.card--website:hover .website__content {
  display: flex;
  transition: all 0.3s ease;
}
.card--website:hover img {
  transform: scale(1.1);
  transition: transform 4s ease-out 0s;
}
.card--website::before {
  content: "";
  position: absolute;
  z-index: 1;
  height: 100%;
  width: 100%;
  background-color: var(--color-black);
  opacity: 0.2;
  transition: opacity 0.3s ease;
}
.card--website img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
}
.card--website .website__content {
  display: flex;
  flex-direction: column;
  z-index: 1;
  padding: var(--double-padding);
  height: 100%;
  max-width: 100%;
}
@media (min-width: 90em) {
  .card--website .website__content {
    padding: var(--quadriple-padding);
    max-width: 80%;
  }
}
.card--website .website__name {
  font-size: 0.875rem;
  color: white;
  margin-bottom: var(--base-padding);
  width: fit-content;
  text-transform: uppercase;
  letter-spacing: 4px;
}
.card--website .website__subtitle {
  color: white;
}
.card--website .website__summary {
  color: white;
  margin-top: auto;
}
.card--website .website__tag {
  position: absolute;
  z-index: 1;
  bottom: 0.75rem;
  right: 0.75rem;
  border: 1px solid white;
  border-radius: 50%;
  padding: 0.5rem;
}
@media (min-width: 768px) {
  .card--website .website__tag {
    bottom: var(--base-padding);
    right: var(--base-padding);
  }
}
.card--website .website__tag svg {
  fill: #fff;
}
.card--website .website__url {
  position: absolute;
  bottom: 0.75rem;
  right: 3.5rem;
  padding: 0.5rem;
}
@media (min-width: 768px) {
  .card--website .website__url {
    bottom: var(--base-padding);
    right: 4rem;
  }
}
.card--service {
  filter: none;
  flex-direction: column;
  background-color: white;
  padding: var(--base-padding);
}
.card--service .card__icon {
  height: 10rem;
  width: 10rem;
}
.card--service .card__icon img {
  width: 100%;
  height: 100%;
}
.card--service .card__info {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.card--service .card__name {
  margin-bottom: var(--base-padding);
}
.card--service .card__summary {
  font-size: 0.875rem;
}

.certificate {
  display: flex;
  flex-direction: column;
}
.certificate img {
  height: 4rem;
  width: 10rem;
  margin-bottom: 1rem;
  margin-right: auto;
}
.certificate p {
  margin-bottom: 0;
}

.swiper-container {
  overflow: visible;
}

.swiper-slide {
  height: auto;
  background-color: white;
}

.swiper-button-prev, .swiper-button-next {
  color: var(--color-orange);
}

.swiper-pagination-bullet {
  background: var(--color-orange);
}

.form__container {
  width: 100%;
  font-size: 0;
}

.control-group {
  position: relative;
  margin-bottom: var(--base-padding);
}
.control-group.input--filled label {
  top: -1rem;
}
.control-group.input--focus input {
  outline-color: var(--color-red);
  background-color: white;
}
.control-group.input--focus label {
  top: -1rem;
}
.control-group--module {
  display: flex;
}
.control-group--module .module-result {
  margin-left: auto;
}
.control-group--submit {
  display: flex;
}
.control-group--submit input {
  border: 1px solid var(--color-mid-blue);
}
.control-group--submit button[type=submit] {
  background-color: var(--color-mid-blue);
  color: white;
  padding: 1rem;
}

.half--input {
  width: 100%;
  display: inline-flex;
  flex-direction: column;
}
@media (min-width: 768px) {
  .half--input {
    width: 50%;
  }
}

@media (min-width: 768px) {
  .half--input-left {
    padding-right: 0.625rem;
  }
}

@media (min-width: 768px) {
  .half--input-right {
    padding-left: 0.625rem;
  }
}

.third--input {
  width: 100%;
  display: inline-flex;
  flex-direction: column;
}
@media (min-width: 768px) {
  .third--input {
    width: 33.3%;
  }
}

@media (min-width: 768px) {
  .third--input-left {
    padding-right: 0.625rem;
  }
}

@media (min-width: 768px) {
  .third--input-right {
    padding-left: 0.625rem;
  }
}

input, select, textarea {
  width: 100%;
  display: block;
  font-size: 16px;
  font-size: 1rem;
  padding: 1em;
  margin: 0;
  color: var(--color-mid-blue);
  border-radius: 0;
  vertical-align: middle;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 1px solid #eee;
  background-color: #ffffff;
  transition: background-color 0.2s, border-color 0.2s;
}

select {
  border-right: 1rem solid #fff;
}

textarea {
  height: 15rem;
}

input::placeholder, textarea::placeholder, input.WatermarkText, textarea.WatermarkText {
  color: var(--color-mid-blue);
}

input:focus + label, textarea:focus + label {
  top: -1rem;
}

label {
  display: block;
  font-size: 1rem;
  /*position: absolute;
     top: 1rem;
  bottom: 0;
  display: block;
  transition: all .2s;
  z-index: -1;*/
}

.checkbox--input {
  display: flex;
  align-items: center;
}
.checkbox--input label {
  position: initial;
}

input[type=checkbox] {
  margin-right: 0.5rem;
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

input[type=checkbox] + label {
  position: relative;
  top: 0;
  cursor: pointer;
  pointer-events: auto;
  margin: 0;
  padding: 0;
  display: inline-flex;
  align-items: center;
  color: var(--font-color);
  z-index: 1;
}

input[type=checkbox] + label::before {
  content: "";
  -webkit-appearance: none;
  background-color: #fff;
  border: 1px solid var(--color-mid-blue);
  padding: 10px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 1rem;
  width: 19px;
  height: 19px;
}

input[type=checkbox]:checked + label::before {
  background-color: var(--color-red);
  border-color: var(--color-red);
}

input[type=checkbox]:checked + label::after {
  content: "";
  display: block;
  position: absolute;
  top: 2px;
  left: 7px;
  width: 6px;
  height: 14px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

input[type=submit] {
  width: auto;
  background-color: var(--color-orange);
  color: white;
  padding: 1rem 1.75rem;
  font-size: 1.125rem;
  border: 1px solid var(--color-orange);
  border-radius: var(--border-radius);
  cursor: pointer;
}
.filters {
  background-color: var(--color-grey);
  padding: var(--base-padding) var(--base-padding) 0 var(--base-padding);
  margin-bottom: var(--base-padding);
  font-size: 0;
}
.filters input[type=submit] {
  width: 100%;
  padding: 1rem;
}
.filters .control-group {
  margin-bottom: var(--half-padding);
}

.logon-password-retrieval-link {
  display: block;
  margin-top: var(--base-padding);
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
.alert--success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}
.alert--danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}