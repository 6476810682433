// ==========================================================================
//   Grid.
// ==========================================================================

.grid {
    display: flex;
    display: grid;
    grid-gap: 1rem;
}

.no-cssgrid .grid {
    flex-direction: row;
    flex-wrap: wrap;
}

.grid--two {
    grid-template-columns: 1fr;

    @include breakpoint('md') {
        grid-template-columns: 1fr 1fr;
    }
}

.no-cssgrid .grid--two {
    flex-direction: row;
    flex-wrap: wrap;

    .grid-item {
        width: calc(50% - 1rem);
        margin: .5rem;
    }
}

.grid--three {
    grid-template-columns: 1fr;

    @include breakpoint('md') {
        grid-template-columns: 1fr 1fr 1fr;
    }
}

.no-cssgrid .grid--three {
    .grid-item {
        width: calc(33.33% - 1rem);
        margin: 0.5rem;
    }
}

.grid--four {
    grid-template-columns: 1fr;
}

.no-cssgrid .grid--four {
    .grid-item {
        width: calc(25% - 1rem);
        margin: 0.5rem;
    }
}

.grid--five {
    grid-template-columns: 1fr;
}

.no-cssgrid .grid--five {
    .grid-item {
        width: calc(20% - 1rem);
        margin: 0.5rem;
    }
}

.grid--featured { //doesnt need to be in grid now amended
    grid-template-columns: 1fr;
    grid-gap: 0;
    background-color: #101110;

    @include breakpoint('md') {
        grid-template-columns: 1fr 1fr;
    }

    div:not(.grid-intro) {
        margin: var(--base-padding);

        @include breakpoint('md') {
            margin: 0;
        }

        &:nth-child(2) {
            margin-bottom: 0;
        }
    }

    
}

.grid--clients {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 3rem;

    @include breakpoint('md') {
        grid-template-columns: repeat(6, 1fr);
        grid-gap: 5rem;
    }
}

.grid--awards {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 3rem;

    @include breakpoint('md') {
        grid-template-columns: repeat(6, 1fr);
    }
}

.grid--recommendations {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 3rem;

    @include breakpoint('md') {
        grid-template-columns: repeat(3, 1fr);
    }
}

.grid--websites {
    grid-template-columns: repeat(2, 1fr);
    margin: 1rem;

    @include breakpoint('md') {
        grid-template-columns: repeat(4, 1fr);
    }
}

.grid--services {
    grid-template-columns: repeat(2, 1fr);
    width: 50%;
}

.grid-intro {
    grid-row: 1;

    @include breakpoint('md') {
        grid-row: 1/3;
    }
}

.grid--certifications {
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;

    @include breakpoint('md') {
        grid-template-columns: repeat(5, 1fr);
    }
}

.works {
    display: none;
}

.grid-gap-4 {
    grid-gap: var(--triple-padding);

    @include breakpoint('md') {
        grid-gap: var(--quadriple-padding);
    }
}

.grid-gap-8 {
    grid-gap: var(--triple-padding);

    @include breakpoint('md') {
        grid-gap: var(--eight-padding);
    }
}