h1,
h2,
h3,
h4,
h5,
h6{
    margin-bottom: var(--base-padding);
    line-height: 1.5;
    @import url('https://fonts.googleapis.com/css2?family=PT+Serif:ital,wght@0,400;0,700;1,400;1,700&display=swap');
    font-family: 'PT Serif', serif;
}

h1 {
    font-size: 3.5rem;
}
h2 {
    font-size: 3rem;
    line-height: 1.2;

    @include breakpoint('md') {
        font-size: 3.5rem;
    }
}
h3 {
    font-size: 1.875rem;
}
h4,
h5,
h6 {
    font-size: 1.25rem;
}

a {
    display: block;
    color: var(--color-island);
}

p + p, p + a, p + h3 {
    margin-top: var(--base-padding);
}

ul.bullet-list {
    margin-left: var(--double-padding);
    margin-bottom: 0;

    @include breakpoint('md') {
        margin-bottom: var(--base-padding);
    }

    li {
        padding-bottom: var(--half-padding);
    }
}

ul.stat-list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    list-style: none;

    @include breakpoint('md') {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }

    li {
        display: flex;
        align-items: center; 
        flex-direction: column;
        padding: var(--base-padding);
        text-align: center;
        font-size: 1rem;

        @include breakpoint('md') {
            margin-right: var(--base-padding);
            font-size: 20px;
        }
    }

    span {
        color: var(--color-orange);
        margin-right: .5rem;
        font-size: 2rem;
        font-weight: 900;

        @include breakpoint('md') {
            font-size: 7rem;
        }
    }
}

.center-text {
    text-align: center;
}

.filters {
    display: none;
    list-style: none;

    a {
        text-decoration: none;
    }
}

.filter {
    position: relative;
    min-width: auto;
    width: 100%;
    
    @include breakpoint('md') {
        position: absolute;
        right: var(--base-padding);
        bottom: var(--base-padding);
        min-width: 20rem;
        width: auto;
    }

    input {
        width: 100%;
        padding: 1rem;
    }
}