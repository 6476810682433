// ==========================================================================
//   Settings.
// ==========================================================================

$colours: white, black, darkgrey, island, cobalt, zinc, alloy, pigeon, cadet, red, maroon,
    orange, green, type;

:root {
    --grid-gutter: 1.25rem;

    --half-padding: 0.625rem;
    --base-padding: 1.25rem;
    --double-padding: 2.5rem;
    --triple-padding: 3.75rem;
    --quadriple-padding: 5rem;
    --eight-padding: 8rem;

    --base-border-radius: 0.375rem;

    --font-color: var(--color-type);
    --font-heading: 'PT Serif',sans-serif;
    --font-base: 'Raleway',sans-serif;

    --color-white: white;

    --color-orange: #DD8D74;

    --color-black: #030607;

    //--color-darkgrey: #3d3d3d;
    --color-darkgrey: #20221D;

    //--color-type: #495662;
    --color-type: #20221D;

    --color-facebook: #3b5998;
    --color-instagram: radial-gradient(
        circle at 30% 107%,
        #fdf497 0%,
        #fdf497 5%,
        #fd5949 45%,
        #d6249f 60%,
        #285aeb 90%
    );
    --color-twitter: #1da1f2;
    --color-linkedin: #0077b5;
    --color-whatsapp: #25d366;
}
