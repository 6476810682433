/* ==========================================================================
   Footer.
   ========================================================================== */

   .footer {
     position: relative;
     width: 100%;
     background-color: var(--color-black);
     color: white;
     background-repeat: no-repeat;
     background-position: top center;
     text-align: center;
     font-size: 1rem;

     @include breakpoint('md') {
        text-align: left;
      }
 
     &__bar {
         background-color: white;
         color: var(--color-mid-blue);
     }
 
     .container--flex {
         position: relative;
         z-index: 1;
         flex-direction: column;

         @include breakpoint('md') {
            flex-direction: row;
             flex-wrap: wrap;
          }
     }

 .container-full {
     max-width: 1920px;
 }

 h1, h2, h3, h4, h5, h6,
 .h1, .h2, .h3, .h4, .h5, .h6 {
     color: var(--color-mid-blue);
     margin: 0 0 var(--base-padding);
 }

 a {
     color: white;
     font-size: 1rem;
     text-decoration: none;

     &:hover {
         text-decoration: underline;
         color: white;
     }
 }

 ul {
     padding: 0;
 }

 li {
     list-style: none;
     padding: 0;
     margin: 0;
     margin-bottom: var(--double-padding);
     line-height: 1.5;
 }

 p {
     line-height: 2rem;
 }

 img {
     margin-bottom: var(--base-padding);
 }
 
 .logo {
     flex: 1 0 auto;
     padding: 0;
     width: 10.625rem;
     margin: 0 auto var(--triple-padding) auto;

     @include breakpoint('md') {
        margin: 0 0 var(--triple-padding) 0;
    }
     
     h1 {
         margin: 0;
     }
 
     &::after {
         display: none;
     }
 }
 
 .footer__content {
     display: flex;
     flex-direction: column;
     margin-top: var(--triple-padding);
     margin-bottom: var(--base-padding);

     @include breakpoint('md') {
        flex: 1 0 50%;
      }

      @include breakpoint('lg') {
         flex: 1 0 auto;
      }
     
     h2 {
         font-size: 1.5rem;
     }

     &--right {
         text-align: right;
     }
 }

 .footer__content--credit {
     text-align: center;

     span:not(.icon) {
         color: #E8E7E7;
         margin-bottom: var(--half-padding);
     }

     @include breakpoint('lg') {
        text-align: right;
      }
 }
}

.bullets-social {
 display: flex;
 margin: 0 auto;
 margin-bottom: var(--base-padding);
 justify-content: center;

 @include breakpoint('md') {
    margin: 0;
     margin-bottom: var(--base-padding);
  }

 @include breakpoint('lg') {
    justify-content: flex-start;
  }

 li {
     margin-right: 1rem;
 }

 a:hover {
     text-decoration: none;
     
     span::before {
         color: var(--color-red);
     }
 }

 span::before {
     font-size: 2rem;
 }
}

.backtotop {
 font-size: 0.875rem;
 display: flex;
 align-self: center;
 align-items: center;
 margin-bottom: var(--base-padding);

 @include breakpoint('md') {
    align-self: flex-end;
     margin-bottom: auto;
  }
}

.backtotop .icon {
 height: 2.5rem;
 width: 2.5rem;
 display: inline-flex;
 border: 2px solid var(--color-orange);
 justify-content: center;
 align-items: center;
 margin-left: 1rem;
 padding: 8px;
}

.footer__image {
 position: absolute;
 bottom: 0;
 right: 0;
 height: 20rem;
 width: 34rem;
}

.footer__socials {
 display: flex;
 justify-content: center;
 margin-top: var(--triple-padding);

 @include breakpoint('md') {
    justify-content: flex-start;
  }

 li {
     margin-right: var(--base-padding);
 }
}

.footer__links {
    display: none;

    @include breakpoint('md') {
        display: block;
    }
}