.form__container {
	width: 100%;
	font-size: 0;
}

.control-group {
	position: relative;
    margin-bottom: var(--base-padding);
    
    &.input--filled {
        label {
            top: -1rem;
        }
    }

    &.input--focus {
        input {
            outline-color: var(--color-red);
            background-color: white;
        }
        label {
            top: -1rem;
        }
    }

    &--module {
        display: flex;

        .module-result {
            margin-left: auto;
        }
    }

    &--submit {
        display: flex;

        input {
            border: 1px solid var(--color-mid-blue);
        }

        button[type=submit] {
            background-color: var(--color-mid-blue);
            color: white;
            padding: 1rem;
        }
    }
}

.half--input {
	width: 100%;
	display: inline-flex;
    flex-direction: column;

    @include breakpoint('md') {
        width: 50%;
    }
}

.half--input-left {    
    @include breakpoint('md') {
        padding-right: 0.625rem;
    }
}

.half--input-right {
    @include breakpoint('md') {
        padding-left: 0.625rem;
    }
}

.third--input {
	width: 100%;
	display: inline-flex;
    flex-direction: column;

    @include breakpoint('md') {
        width: 33.3%;
    }
}

.third--input-left {    
    @include breakpoint('md') {
        padding-right: 0.625rem;
    }
}

.third--input-right {
    @include breakpoint('md') {
        padding-left: 0.625rem;
    }
}

input,select,textarea {
    width: 100%;
    display: block;
    font-size: 16px;
    font-size: 1rem;
    padding: 1em;
    margin: 0;
    color: var(--color-mid-blue);
    border-radius: 0;
    vertical-align: middle;
    -webkit-box-shadow: none;
    box-shadow: none;
    border: 1px solid #eee;
    background-color: #ffffff;
    transition: background-color .2s,border-color .2s
}

select {
    border-right: 1rem solid #fff;
}

textarea {
    height: 15rem;
}

input::placeholder,textarea::placeholder, input.WatermarkText, textarea.WatermarkText {
	color: var(--color-mid-blue);
}

input:focus + label, textarea:focus + label {
	top: -1rem;
}

label {
    display: block;
	font-size: 1rem;
	/*position: absolute;
    top: 1rem;
	bottom: 0;
	display: block;
	transition: all .2s;
	z-index: -1;*/
}

.checkbox--input {
    display: flex;
    align-items: center;

    label {
        position: initial;
    }
}

input[type=checkbox] {
    margin-right: .5rem;
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer
}

input[type=checkbox]+label {
	position: relative;
	top: 0;
    cursor: pointer;
    pointer-events: auto;
    margin: 0;
    padding: 0;
    display: inline-flex;
    align-items: center;
	color: var(--font-color);
	z-index: 1;
}

input[type=checkbox]+label::before {
    content: "";
    -webkit-appearance: none;
    background-color: #fff;
    border: 1px solid var(--color-mid-blue);
    padding: 10px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 1rem;
    width: 19px;
    height: 19px
}

input[type=checkbox]:checked+label::before {
    background-color: var(--color-red);
    border-color: var(--color-red);
}

input[type=checkbox]:checked+label::after {
    content: "";
    display: block;
    position: absolute;
    top: 2px;
    left: 7px;
    width: 6px;
    height: 14px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg)
}

input[type=submit] {
	width: auto;
	background-color: var(--color-orange);
	color: white;
    padding: 1rem 1.75rem;
    font-size: 1.125rem;
    border: 1px solid var(--color-orange);
    border-radius: var(--border-radius);
	cursor: pointer;

	&:hover {
		//background-color: #A71D11;
	}
}

.filters {
    background-color: var(--color-grey);
    padding: var(--base-padding) var(--base-padding) 0 var(--base-padding);
    margin-bottom: var(--base-padding);
    font-size: 0;

    input[type=submit] {
        width: 100%;
        padding: 1rem;
    }

    .control-group {
        margin-bottom: var(--half-padding);
    }
}

.logon-password-retrieval-link {
    display: block;
    margin-top: var(--base-padding);
}


.alert {
    position: relative;
    padding: .75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: .25rem;

    &--success {
        color: #155724;
        background-color: #d4edda;
        border-color: #c3e6cb;
    }

    &--danger {
        color: #721c24;
        background-color: #f8d7da;
        border-color: #f5c6cb;
    }
}