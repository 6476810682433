@each $colour in $colours {
    .background--#{$colour} {
        background-color: var(--color-#{$colour});
    }
}

.background--skew-left {
    //background: linear-gradient(135deg,#000 0%,#3d3d3d 50%,#fff 50%,#fff 100%);
    background: linear-gradient(135deg,#20221d 0%,#20221d 50%,#eee 50%,#eee 100%);
}

.background--skew-right {
    //background: linear-gradient(135deg, #fff 0%, #fff 50%, #3d3d3d 50%, #000 100%);
    background: linear-gradient(135deg, #fff 0%, #fff 50%, #20221d 50%, #20221d 100%);
}

.background--image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.background--black {
    color: white;
}