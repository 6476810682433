// ==========================================================================
//   Eric Meyers Reset + Modification.
// ==========================================================================
// sass-lint:disable indentation
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-weight: inherit;
    font-style: inherit;
    font-size: 100%;
    font-family: inherit;
    vertical-align: baseline;
}

html {
    width: 100%;
    box-sizing: border-box;
    font-family: var(--font-base);
    font-weight: 400;
    line-height: 1.25;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -ms-overflow-style: scrollbar;
}

* {
    margin: 0;
    padding: 0;
    font-family: var(--font-base);

    &,
    &::before,
    &::after {
        box-sizing: inherit;
    }
}

body {
    color: var(--font-color);
    font-family: var(--font-base);
    font-size: 1rem;
    line-height: 1.5;
    overflow-wrap: break-word;
    scroll-behavior: smooth;
    overflow-x: hidden;
    background-color: #eee;
}

hr {
    height: 0;
    overflow: visible;
}

nav ol,
nav ul {
    list-style: none;
}

.visuallyhidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

// Typography

a {
    //text-decoration: none;
    background-color: transparent;
    -webkit-text-decoration-skip: objects;

    &:hover {
        text-decoration: underline;
        cursor: pointer;
    }
}

b,
strong {
    font-weight: bold;
}

::selection,
::-moz-selection {
    background-color: var(--selection-color);
    color: var(--font-color);
    text-shadow: none;
}

// Images

img {
    max-width: 100%;
    height: auto;
    -ms-interpolation-mode: bicubic;
    display: inline-block;
    vertical-align: middle;
    border: 0;
    border-style: none;
}

// Forms

button,
input,
select,
textarea {
    color: inherit;
    font: inherit;
}

button,
input,
select {
    overflow: visible;
}

button {
    background: transparent;
    border: 0;
    padding: 0;
    cursor: pointer;
    -webkit-font-smoothing: inherit;
    letter-spacing: inherit;
}

button,
[type='button'],
[type='reset'],
[type='submit'] {
    -webkit-appearance: button;
}

input {
    overflow: visible;
}

textarea {
    resize: vertical;
    overflow: auto;
}

select {
    text-transform: none;
}

::-moz-focus-inner {
    border-style: none;
    padding: 0;
}

:focus {
    outline: var(--color-cobalt) auto 1px;
}

// Tables

table {
    border-collapse: collapse;
    border-spacing: 0;
}

caption,
th,
td {
    text-align: left;
    font-weight: normal;
}

// Embeded content

audio,
canvas,
iframe,
svg,
video {
    vertical-align: middle;
}

audio:not([controls]) {
    display: none;
    height: 0;
}

svg:not([fill]) {
    fill: currentColor;
}

svg:not(:root) {
    overflow: hidden;
}

// Interaction

a,
area,
button,
input,
label,
select,
summary,
textarea,
[tabindex] {
    touch-action: manipulation;
}

// Accessibility

[aria-busy='true'] {
    cursor: progress;
}

[aria-controls] {
    cursor: pointer;
}

[aria-disabled='true'],
[disabled] {
    cursor: not-allowed;
}

[aria-hidden='false'][hidden]:not(:focus) {
    clip: rect(0, 0, 0, 0);
    display: inherit;
    position: absolute;
}

video {
    object-fit: cover;
    height: 100%;
    width: 100%;
}

// Details
details {
    margin-bottom: 1rem;
}
