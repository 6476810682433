.coverlink {
    position: absolute;
    z-index: 4;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    cursor: pointer;
}

.hiddentext {
    border: 0;
    font: 0/0 a;
    text-shadow: none;
    color: transparent;
}

.text-center {
    text-align: center;
}

.font-size-17 {
    font-size: 17px;
}

.font-size-23 {
    font-size: 23px;
}

.angle-top {
    content: " ";
    position: absolute;
    z-index: 1;
    top: -30px;
    left: 0;
    width: 0;
    height: 0;
    border-top: 30px solid transparent;
    border-right: 30px solid #fff;
}

.angle-top {
    color: #fff;
    border-right-width: 100%;
    border-top: 80px solid transparent;
    top: -80px;
}

.angle-bottom {
    content: " ";
    position: absolute;
    z-index: 1;
    bottom: -30px;
    left: 0;
    width: 0;
    height: 0;
    border-bottom: 30px solid transparent;
    border-left: 30px solid #fff;
}

.angle-bottom {
    color: #fff;
    border-left-width: 100%;
    border-bottom: 80px solid transparent;
    bottom: -80px;
}

.counter-top-skew {
    padding-top: 160px !important;
}

.key svg {
    margin: 1rem;

    &:first-child {
        margin-left: 0;
    }
}

.link {
    display: inline-block;
    width: auto;
    color: var(--color-orange);
    text-decoration: none;

    &:hover {
        text-decoration: none;

        &::after {
            width: 100%;
        }

        svg {
            width: 2rem;
        }
    }
    
    svg {
        fill: var(--color-orange);
        width: 1.25rem;
        height: 1.25rem;
        transition: width 0.3s ease;
    }

    &::after {
        display: block;
        content: "";
        width: 0;
        height: 1px;
        background-color: var(--color-orange);
        transition: width 0.3s ease;
        margin-top: .5rem;
    }
}