// ==========================================================================
//   Breakpoints.
// ==========================================================================

// For syncing JavaScript and CSS media queries
body::before {
    content: 'xs';
    display: none;
    visibility: hidden;

    @media (min-width: 480px) {
        content: 'sm';
    }

    @media (min-width: 768px) {
        content: 'md';
    }

    @media (min-width: 60em) {
        content: 'lg';
    }

    @media (min-width: 90em) {
        content: 'xl';
    }
}

$breakpoints: (
    xs: 0,
    sm: 480px,
    md: 768px,
    lg: 60em,
    xl: 90em
);

@mixin breakpoint($breakpoint) {
    @if map-has-key($breakpoints, $breakpoint) {
        @media (min-width: map-get($breakpoints, $breakpoint)) {
            @content;
        }
    }
}
