.logo {
    /*background-color: #ED5E15;
    background-image: linear-gradient(to right,#CA35A3,#ED5E15);
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1rem;
    padding: 1rem;
    width: 10rem;
    */
    @include breakpoint('md') {
      width: 12rem;
    }

    &:hover {
      path {
        fill: var(--color-orange);
      }
    }
    
    path {
      transition: fill .3s ease;
    }

    
  }

  header {
    .container {
      justify-content: space-between;
      align-items: center;
      max-width: none;
    }
  }